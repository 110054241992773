<template>
  <v-card>
    <v-card-title>"{{ getTour().name }}" - Orte hinzufügen</v-card-title>
    <v-card-text>
      <poi-map :pois="GET_POIS" style="height: 60vh" :zoom="15">
        <template v-slot:default="slotProps">
          <poi-title :poi="slotProps.poi" />
          <tour-toggle-poi-button :poiId="slotProps.poi.id" :tourId="id" />
        </template>
      </poi-map>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" block @click="finishEdit()">Fertig</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { backButtonPageMixin } from "../mixins/backButtonPageMixin";
import { createNamespacedHelpers } from "vuex";
import { GET_POIS } from "../store/modules/poistore";
import { GET_TOUR } from "../store/modules/userstore";
import PoiTitle from "../components/poi/PoiTitle.vue";
import PoiMap from "../components/PoiMap.vue";
import TourTogglePoiButton from "../components/tour/TourTogglePoiButton.vue";
const pois = createNamespacedHelpers("pois");
const user = createNamespacedHelpers("user");

export default {
  components: { PoiMap, PoiTitle, TourTogglePoiButton },
  name: "TourPoiEdit",
  mixins: [backButtonPageMixin],
  props: {
    id: {
      type: String,
    },
  },
  computed: {
    ...pois.mapGetters([GET_POIS]),
    ...user.mapGetters([GET_TOUR]),
  },
  methods: {
    finishEdit() {
      this.$router.push({ path: `/tour/${this.id}` });
    },
    getTour() {
      return this.GET_TOUR(this.id);
    },
  },
};
</script>
