<template>
  <v-checkbox
    v-model="poiAddedToTour"
    :label="getCheckboxText(poiAddedToTour)"
    :value="poiAddedToTour"
  >
  </v-checkbox>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import {
  TOGGLE_POI_IN_TOUR,
  IS_POI_IN_TOUR,
} from "../../store/modules/userstore";
const user = createNamespacedHelpers("user");

export default {
  name: "TourTogglePoiButton",
  props: {
    tourId: {
      type: String,
    },
    poiId: {
      type: Number,
    },
    hideLabel: {
      type: Boolean,
    },
  },
  computed: {
    ...user.mapGetters([IS_POI_IN_TOUR]),
    poiAddedToTour: {
      get() {
        return this.IS_POI_IN_TOUR(this.getQueryObject());
      },
      set(value) {
        console.log(
          `toggle poi to tour ${this.tourId} and ${this.poiId} ${value}`
        );
        this.TOGGLE_POI_IN_TOUR(this.getQueryObject());
      },
    },
  },
  methods: {
    ...user.mapActions([TOGGLE_POI_IN_TOUR]),
    getCheckboxText(visited) {
      if (this.hideLabel === true) {
        return "";
      }
      return visited ? "Ort entfernen" : "Ort hinzufügen";
    },
    getQueryObject() {
      return { tourId: this.tourId, poiId: this.poiId };
    },
  },
};
</script>
